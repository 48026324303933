import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const BlogListTwo = ({ data, StyleVar }) => {
  return (
    <div className={`rn-card ${StyleVar}`}>
      <div className="inner">
        <div className="thumbnail">
          <Link
            to={`/product/${data?.productType}/${data.slug?.current}`}
            className="image"
          >
            {data.mainImage && (
              <img
                src={`https://cdn.sanity.io/images/lzh3bipm/production/${
                  data.mainImage.asset._ref.split("-")[1]
                }-${data.mainImage.asset._ref.split("-")[2]}.${
                  data.mainImage.asset._ref.split("-")[3]
                }`}
                alt={data.title}
              />
            )}
          </Link>
        </div>
        <div className="content">
          <h4 className="title">
            <Link to={`/product/${data?.productType}/${data.slug?.current}`}>
              {data.title}
            </Link>
          </h4>
        </div>
      </div>
    </div>
  );
};
BlogListTwo.propTypes = {
  data: PropTypes.object,
};
export default BlogListTwo;
