import React, { Component } from 'react';
// import GoogleMapReact from 'google-map-react';
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps";

// const AnyReactComponent = ({ text }) => <div>{text}</div>;
class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 23.0805248,
      lng: 72.6680287
    },
    zoom: 11
  };

  render() {

    const MapWithAMarker = withScriptjs(withGoogleMap(props =>
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: 23.0805248, lng: 72.6680287 }}
        >
          <Marker
              position={{ lat: 23.0805248, lng: 72.6680287 }}
          />
        </GoogleMap>
    ));

    return (
      // Important! Always set the container height explicitly
      <div className="google-map-style-1">
        {/*<GoogleMapReact*/}
        {/*  bootstrapURLKeys={{ key: 'AIzaSyD-G_YCv_F45rntfXcLgOM3jsZcHRGKt8U' }}*/}
        {/*  defaultCenter={this.props.center}*/}
        {/*  defaultZoom={20}*/}
        {/*>*/}
        {/*  <AnyReactComponent*/}
        {/*    lat={21.225487}*/}
        {/*    lng={72.896577}*/}
        {/*    text="My Marker"*/}
        {/*  />*/}
        {/*</GoogleMapReact>*/}

        {/*<MapWithAMarker*/}
        {/*    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD-G_YCv_F45rntfXcLgOM3jsZcHRGKt8U&v=3.exp&libraries=geometry,drawing,places"*/}
        {/*    loadingElement={<div style={{ height: `100%` }} />}*/}
        {/*    containerElement={<div style={{ height: `100%` }} />}*/}
        {/*    mapElement={<div style={{ height: `100%` }} />}*/}
        {/*/>*/}

          <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3670.452523735992!2d72.66802867541277!3d23.080524779133203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e80d171f7bc6f%3A0x13939d0e6eac0147!2sWel-Tech%20Weighing%20Systems!5e0!3m2!1sen!2sin!4v1700902800184!5m2!1sen!2sin"
              width="100%" height="100%" allowFullScreen="" loading="lazy"
              referrerPolicy="no-referrer-when-downgrade" />

      </div>
    );
  }
}

export default GoogleMapStyle;
