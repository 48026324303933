import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import Separator from "../separator/Separator";
import BrandThree from "../brand/BrandThree";
import welcome from "../../assets/images/welcome.png";
import certificates from "../../assets/images/certificate_small.png";
import productRange from "../../assets/images/product_range.png";

const Profile = () => {
  return (
    <>
      <SEO title="Profile | Weltech Weighing System" canonicalUrl="profile" />
      <Layout>
        <div className="main-content">
          <div className="rn-service-area rn-section-gap">
            <div className="container">
              <div className="row pt--10">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    title="Profile"
                  />
                  <div className="service-area pt-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="content">
                            <p className="mb--10">
                              Wel-Tech Weighing Systems are one of the leading
                              manufacturers of a wide range of Electronic,
                              Mechanical, and Electro - Mechanical Weigh Bridges
                              & Weighing Scales. Our range of product includes
                              Weighing Pad Systems, Mechanical Weighbridge,
                              Table Top Scale, Platform Scale, Bag Filling, Pit
                              Type, Pit Less Weighbridges, Axel and Batching
                              System. In addition to these, we also offer
                              Junction Box, Load Cells, Jumbo Display and
                              Lightning Protector.
                            </p>
                          </div>
                          <div className="content">
                            <p className="mb--10">
                              We also provide repair services for these
                              products. Backed with around 36 years of
                              experience, our range of products is highly
                              recognized and appreciated for their high
                              performance, consistent quality and additional
                              features. Product range includes Electronic and
                              Mechanical Weighing Scale like Electronic
                              Weighbridge, Movable Weighbridge, Mechanical
                              Weighbridge, Electronic Weighing Scale, Weighing
                              Automation, and Weighbridge Accessories.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <img src={welcome} alt="welcome" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt--60">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Product Ranges"
                  />
                  <div className="service-area pt-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="content">
                            <p className="mb--10">
                              Also offer Lorry Weigh Bridge, Liquid Filling
                              System, Batching System, Ginning Automation, Mis
                              System, Milk Handling System, Online Tea Filling
                              Machine, Weighing Indicator, Axle Scale, Truck
                              Axle Scale, Hot Mix Plant System, Drum Mix Plant
                              System, Multi Scale Synchronization System, Dairy
                              Automation, Chemical Filling System, Powder
                              Filling System, Wireless Weighing System, Frame
                              Proof Scales, Barrel Filling System, Water Level
                              Controller System, Toll Barrier System, Fully
                              Electronic Weigh Bridge, Mechanical Weighbridge,
                              Load cell, Digital Load Cell, Oil Filling System,
                              Oil Filling Machine, Filling Systems, Automation,
                              Pit less Weigh Bridge, Pit Weigh Bridge,
                              Weighbridge, Electronic Weighbridge, Double Ended
                              Shear Beam Road Cell, Compression Load Cell, S
                              Type Load Cell, Steel Yard Type Platform Scale,
                              Intelligent Terminal, Intelligent Terminal
                              Systems, Liquid Filling Machine, Paint Filling
                              Machine, Solvent Filling Machine, Resin Filling
                              Machine
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <img src={productRange} alt="productRange" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt--60">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Quality"
                  />
                  <div className="service-area pt-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="content">
                            <p className="mb--10">
                              All of our products are quality tested and
                              reliable in performance. All of our products are
                              CE certified. These products are also endowed with
                              unique features like platform design for easy
                              carry out, adopt special protective technology,
                              suitable for any environment in applications,
                              corrosion resistant finish and durable finish
                              standards. Client's satisfaction is our prime
                              objective, this is why we provide them with high
                              performance products at market leading prices. Our
                              products basically cater the needs of Forestry
                              Industry, Agriculture Industry and Construction
                              Industry. Till now, we have catered to more than
                              12,000 satisfied clients in INDIA and Abroad which
                              speaks in volumes about the quality of our
                              products.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <img src={certificates} alt="certificates" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt--60">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Why Us?"
                  />
                  <div className="service-area pt-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="content">
                            <p className="mb--10">
                              Under the efficient supervision of our Director
                              Mr. Raman Patel, we are able to carve a niche for
                              ourselves in this domain. Besides, owing to his
                              sharp business expertise, visionary guidance and
                              professional approach, we have become the
                              preferred choice of our clients spread all over
                              the world.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Service Area  */}
          <Separator />
          <div className="row pt--80">
            <div className="col-lg-12">
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle="Our clients"
              />
              <div className="rwt-brand-area pb--60 pt--30">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 mt--10">
                      <BrandThree brandStyle="brand-style-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Profile;
