import React from "react";
import PortableText from "react-portable-text";

const   ProductDetailsContent = ({ data }) => {

  return (
    <>
      <div className="post-page-banner rn-section-gapTop">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="content text-center">
                <div className="page-title">
                  <h1 className="theme-gradient">{data.title}</h1>
                </div>
                <div className="thumbnail alignwide mt--60">
                  {data.mainImage && (
                      <img
                          src={`https://cdn.sanity.io/images/lzh3bipm/production/${data.mainImage.asset._ref.split('-')[1]}-${data.mainImage.asset._ref.split('-')[2]}.${data.mainImage.asset._ref.split('-')[3]}`}
                          alt={data.title}
                      />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-content pt--60 rn-section-gapBottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="content">
                <div className="blog-details-content-description">
                  <PortableText
                      content={data.body}
                      serializers={{
                        h1: (props) => <h1 style={{ color: "red" }} {...props} />,
                        li: ({ children }) => <li className="special-list-item">{children}</li>,
                      }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductDetailsContent;
