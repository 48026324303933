import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import GalleryOne from "./GalleryOne";
import { client } from "../../utils/client";

const Elements = () => {
  const [galleryData, setGalleryData] = useState({ data: [], loading: true });
  const [portFolioData, setPortFolioData] = useState([]);

  const galleryArray = galleryData?.data;

  const getBlogData = async () => {
    setGalleryData({ ...galleryData, loading: true });
    client
      .fetch(
        `*[_type == "products"]{
       ...,
       } | order(_createdAt asc)`,
      )
      .then((response) => {
        setGalleryData({
          data: response.map((res) => ({
            image: res?.mainImage,
            title: res?.title,
          })),
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        setGalleryData({ ...galleryData, loading: false });
      });
  };
  useEffect(() => {
    getBlogData();
  }, []);

  return (
    <>
      <SEO
        title="Gallery | Weltech Weighing System"
        canonicalUrl={"/gallery"}
      />
      <Layout setPortFolioData={setPortFolioData}>
        <div className="main-content">
          {/* Start Elements Area  */}
          <div className="rwt-gallery-area rn-section-gapBottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--20">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Gallery With Lightbox"
                    title="Gallery Style Two."
                    description=""
                  />
                </div>
              </div>
              <div className="row mt_dec--30 row--15">
                {galleryData?.loading ? (
                  <div className="center-flex">
                    {" "}
                    <ReactLoading
                      type="spinningBubbles"
                      color="#1B7284"
                      height={"20%"}
                      width={"20%"}
                    />{" "}
                  </div>
                ) : (
                  <>
                    {galleryArray.map((item) => {
                      const popupLinkImage = [
                        item &&
                          `https://cdn.sanity.io/images/lzh3bipm/production/${
                            item?.image?.asset?._ref.split("-")[1]
                          }-${item?.image?.asset?._ref.split("-")[2]}.${
                            item?.image?.asset?._ref.split("-")[3]
                          }`,
                        // ...popupLinkArray,
                      ];

                      return (
                        <div
                          className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
                          key={item._id}
                        >
                          <GalleryOne
                            galleryItem={item}
                            popupLink={[...new Set(popupLinkImage)]}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Elements;
