import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";

// Pages import Here
import HomeDefault from "./pages/HomeDefault";
import PersonalPortfolio from "./pages/PersonalPortfolio";
import CreativePortfolio from "./pages/CreativePortfolio";

// Elements import Here

import Service from "./elements/service/Service";

import portfolioThreeColumn from "./elements/portfolio/PortfolioThreeColumn";
import PortfolioFullWidth from "./elements/portfolio/PortfolioFullWidth";
import PortfolioGrid from "./elements/portfolio/PortfolioGrid";
import PortfolioBoxLayout from "./elements/portfolio/PortfolioBoxLayout";
import PortfolioDetails from "./pages/PortfolioDetails";
import Testimonial from "./elements/testimonial/Testimonial";
import Gallery from "./elements/gallery/Gallery";
import Contact from "./elements/contact/Contact";
import ContactResponse from "./elements/contact/ContactResponse";

import Products from "./components/blog/Products";
import ProductDetails from "./pages/ProductDetails";
import Error from "./pages/Error";

// Import Css Here
import "./assets/scss/style.scss";
import TermsAndConditions from "./pages/TermsAndConditions";
import LandingPage from "./pages/LandingPage";
import Profile from "./elements/profile/Profile";
import Quality from "./elements/quality/Quality";
import Payment from "./elements/payment/Payment";

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            component={HomeDefault}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/product/:productType/:id"}`}
            exact
            component={ProductDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            exact
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact-response"}`}
            exact
            component={ContactResponse}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/service"}`}
            exact
            component={Service}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/profile"}`}
            exact
            component={Profile}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/quality"}`}
            exact
            component={Quality}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/service"}`}
            exact
            component={Service}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/payment"}`}
            exact
            component={Payment}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/testimonial"}`}
            exact
            component={Testimonial}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/terms-and-conditions"}`}
            exact
            component={TermsAndConditions}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/products"}`}
            exact
            component={Products}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/products/:type"}`}
            exact
            component={Products}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/error"}`}
            exact
            component={Error}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/creative-portfolio"}`}
            exact
            component={CreativePortfolio}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/personal-portfolio"}`}
            exact
            component={PersonalPortfolio}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/gallery"}`}
            exact
            component={Gallery}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/portfolio-three-column"}`}
            exact
            component={portfolioThreeColumn}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/portfolio-full-width"}`}
            exact
            component={PortfolioFullWidth}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/portfolio-grid-layout"}`}
            exact
            component={PortfolioGrid}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/portfolio-box-layout"}`}
            exact
            component={PortfolioBoxLayout}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/portfolio-details/:id"}`}
            exact
            component={PortfolioDetails}
          />

          <Route
            path={`${
              process.env.PUBLIC_URL + "/product_packaging_landingPage"
            }`}
            exact
            component={LandingPage}
          />
          <Route path={"*"} component={Error} />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
