import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import Separator from "../separator/Separator";

const Service = () => {
  return (
    <>
      <SEO title="Service | Weltech Weighing System" />
      <Layout>
        <div className="main-content">
          {/* Start Service Area  */}
          <div className="rn-service-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    title="Service"
                  />
                  <div className="service-area pt-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="content">
                            <p className="mb--10">
                              We are not only offering solutions for weight
                              measurement activity to our clients, but also
                              offering them with personalized and effective ,
                              impelling value added services. Owing to the
                              capabilities of our skillful team, we are offering
                              our services in terms of professional guidance and
                              systematic support.
                            </p>
                            <p className="mb--10">
                              Keeping in pace with the market trends and
                              clients' requirements, we are undertaking
                              customization of our electronic weighing scales on
                              various parameters like size, control panel,
                              display and many others. Our technologically
                              advanced weighing scales are offered along with
                              annual maintenance services so as to meet high
                              level of client satisfaction.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pt--60">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle="Research And Development"
                    />
                    <div className="service-area pt-5">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="content">
                              <p className="mb--10">
                                The weigh scale industry is experiencing change
                                of trends and thus, we are constantly engaged in
                                keeping abreast with the latest innovation and
                                technical advancement in the field. For doing
                                the same, we have developed a modern R&D unit
                                that is managed by diligent R&D professionals.
                                These professionals are provided with relevant
                                training that helps them in carrying out
                                extensive research activities.
                              </p>
                              <p className="mb--10">
                                Our R&D activities are centered towards
                                achieving quality enhancement and innovation of
                                our range of electronic weighing scales. The
                                attributes on which our R&D is conducted are
                                mentioned below:
                              </p>
                              <ul>
                                <li>Technology</li>
                                <li>Material of Construction</li>
                                <li>Design</li>
                                <li>Manufacturing Processes</li>
                              </ul>
                            </div>
                            <div className="content">
                              <p className="mb--10">
                                Besides these, many other factors are taken into
                                consideration so that we are able to incorporate
                                newer attributes in our electronic weighing
                                scales.
                              </p>
                              <p className="mb--10">
                                Information About Weighbridge Maintainance &
                                Care
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Service Area  */}

          <Separator />
        </div>
      </Layout>
    </>
  );
};

export default Service;
