import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import Separator from "../separator/Separator";
import qualityImage from "../../assets/images/high-quality.png";
import ourTeamImage from "../../assets/images/our-team.png";
import whyUsImage from "../../assets/images/whyUs.png";

const Quality = () => {
  return (
    <>
      <SEO title="Quality | Weltech Weighing System" canonicalUrl="quality" />
      <Layout>
        <div className="main-content">
          <div className="rn-service-area rn-section-gap">
            <div className="container">
              <div className="row pt--10">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    title="Quality"
                  />
                  <div className="service-area pt-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="content">
                            <p className="mb--10">
                              Our objective is to consistently improve our
                              quality of work and develop highly improved
                              products by adapting the latest technology in the
                              world. Right from the manufacturing stage to the
                              actual delivering of the products, we strictly
                              adhere to the international quality standards. Our
                              dedicated workforce, which includes quality
                              experts and experienced professional, always
                              remains keen towards removing minutest defects in
                              the production process. These hard core efforts of
                              our technocrats have further helped us in
                              achieving total customer satisfaction for best
                              quality, thereby, making us a reckoned name in the
                              industry.
                            </p>
                          </div>
                          <div className="content">
                            <p className="mb--10">
                              We always look forward for long term business
                              relationship with our client by providing cost
                              efficient, high quality product and services in
                              time bound environment.
                            </p>
                            <div className="content">
                              <p className="mb--10 font-bold">
                                <strong>
                                  “Quality and time bound of execution of work
                                  is mantra of our company”
                                </strong>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <img src={qualityImage} alt="quality" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt--60">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Our Objective"
                  />
                  <div className="service-area pt-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="content">
                            <p className="mb--10">
                              <strong>
                                To be the one stop customer’s solutions for any
                                need in magnetic work.
                              </strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt--60">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Our Team"
                  />
                  <div className="service-area pt-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="content">
                            <p className="mb--10">
                              To give quality, effective, hygenic and affordable
                              product to the customer any organization require
                              professional, efficient, dedicated, highly skilled
                              and experience team. We have develop a team for
                              each department, which we are proud of. Our team
                              for each department is dedicated to provide
                              quality and effective product for the customer.
                              Our team of profession and dedicated people make
                              constant endeavor to provide customize product as
                              per industry need, and they have succeeded in it,
                              through their diligent and intellectual effort.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <img src={ourTeamImage} alt="out team" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt--60">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Why Us?"
                  />
                  <div className="service-area pt-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="content">
                            <ul>
                              <li>
                                Global quality products from appropriate source
                                across the world at very competitive price.
                              </li>
                              <li>Customized products available.</li>
                              <li>
                                Offer complete technical help for our products &
                                job work.
                              </li>
                              <li> Quick customer feedback</li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <img src={whyUsImage} alt="why us" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Service Area  */}
          <Separator />
        </div>
      </Layout>
    </>
  );
};

export default Quality;
