import React, { useState, useEffect } from "react";
import BlogClassicData from "../../../data/blog/BlogList.json";
import BlogListTwo from "./BlogListTwo";
import ReactLoading from "react-loading";
import filters from "./Filters";

const BlogPropTwo = ({ column, blogList = {}, StyleVarProp }) => {
  const { data, loading } = blogList;

  return (
    <>
      <div className="col-lg-12">
        <div className="row row--15">
          {loading ? (
            <div className="center-flex">
              <ReactLoading
                type="spinningBubbles"
                color="#1B7284"
                height={"20%"}
                width={"20%"}
              />
            </div>
          ) : (
            <>
              {data?.map((item) => (
                <div key={item._id} className={column}>
                  <BlogListTwo StyleVar={StyleVarProp} data={item} />
                </div>
              ))}{" "}
            </>
          )}
        </div>
      </div>
      <div className="col-lg-12">
        <div className="rwt-load-more text-center mt--60"></div>
      </div>
    </>
  );
};
export default BlogPropTwo;
