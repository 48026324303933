import React, { useEffect, useState } from "react";
import axios from "axios";
import SEO from "../../common/SEO";

const ContactResponse = () => {
  const [contactData, setContactData] = useState();
  const getResponse = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_firebaseio}/email.json`,
    );
    setContactData(Object.values(res.data));
    console.log("@@@@res", res);
  };
  useEffect(() => {
    getResponse();
  }, []);
  return (
    <div>
      <SEO
        title="Contant Response | Weltech Weighing System"
        canonicalUrl="contact-response"
      />

      <div
        className="d-flex  w-100 p-5"
        style={{ height: "100vh", overflowY: "auto" }}
      >
        <div className="  w-100 h-100 table-responsive">
          <table className="table table-striped table-dark table-hover">
            <thead>
              <tr>
                <th
                  style={{ position: "sticky", top: 0, zIndex: 2 }}
                  scope="col"
                >
                  #
                </th>
                <th
                  style={{ position: "sticky", top: 0, zIndex: 2 }}
                  scope="col"
                >
                  Name
                </th>
                <th
                  style={{ position: "sticky", top: 0, zIndex: 2 }}
                  scope="col"
                >
                  Email
                </th>
                <th
                  style={{ position: "sticky", top: 0, zIndex: 2 }}
                  scope="col"
                >
                  Content No.
                </th>
                <th
                  style={{ position: "sticky", top: 0, zIndex: 2 }}
                  scope="col"
                >
                  Subject
                </th>
                <th
                  style={{ position: "sticky", top: 0, zIndex: 2 }}
                  scope="col"
                >
                  Message
                </th>
              </tr>
            </thead>
            <tbody>
              {contactData?.length &&
                contactData?.map((data, index) => {
                  console.log("@@@data", data, index);
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{data?.name}</td>
                      <td>{data?.email}</td>
                      <td>{data?.phone}</td>
                      <td>{data?.subject}</td>
                      <td>{data?.message}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ContactResponse;
