import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const testimonialData = [
  {
    image: "testimonial-01",
    name: "Mayur Shah, Krutam Architects",
    designation: "",
    location: "Ahmedabad",
    description:
      "“Their work is brilliant... starting a new venture ? Hand it over to them... meanwhile you can focus on what you got to do... designing, Advertising, its all their work. 👍„",
  },
  {
    image: "testimonial-02",
    name: "Ayaz Bhura, Bhura Consultancy",
    designation: "",
    location: "Vadodara",
    description: `“When it comes to creativity and professional service, it's priy advertising that you should think of.„`,
  },
  {
    image: "testimonial-03",
    name: "Sanjay Dhaduk ,Bhagwati School ",
    designation: "",
    location: "Varachha, Surat",
    description:
      "“Have a great work in Surat and Very nice experience with owner and very creative work.„",
  },
  {
    image: "testimonial-04",
    name: "Amandeep Singh, Rapido",
    designation: "",
    location: "Varachha, Surat",
    description:
      "“On time professional service for my business printing need and even in very fair rate ever i seen in surat„",
  },
];

const TestimonialOne = ({ column, teamStyle, marginTop = true }) => {
  return (
    <div className="row row--15">
      {testimonialData?.map((data, index) => (
        <div className={`${column}`} key={index}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            style={{ height: "calc(100% - 30px)" }}
            animateOnce={true}
          >
            <div
              style={{ height: "100%" }}
              className={`rn-box-card ${teamStyle}`}
            >
              <div style={{ height: "100%" }} className="inner">
                <figure className="thumbnail">
                  <img
                    src={`./images/testimonial/${data.image}.jpg`}
                    alt="Corporate React Template"
                  />
                </figure>
                <figcaption
                  style={{
                    height: marginTop ? "calc(100% - 150px)" : "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                  className="content"
                >
                  <p className="description">{data.description}</p>
                  <div>
                    <h2 className="title">{data.name}</h2>
                  </div>
                </figcaption>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};

export default TestimonialOne;
