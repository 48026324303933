import React, { useEffect, useState } from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BlogPropTwo from "./itemProp/BlogPropTwo";
import ReactLoading from "react-loading";
import { client } from "../../utils/client";

const BlogListView = ({
  match: {
    params: { type },
  },
}) => {
  const [blogList, setBlogList] = useState({ data: [], loading: true });

  const getBlogData = async () => {
    setBlogList({ ...blogList, loading: true });
    client
      .fetch(
        `*[_type == "products"]{
       ...,
       } | order(_createdAt asc)`,
      )
      .then((response) => {
        setBlogList({
          data: type
            ? response.filter((data) => data.productType === type)
            : response,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        setBlogList({ ...blogList, loading: false });
      });
  };

  useEffect(() => {
    getBlogData();
  }, [type]);

  return (
    <>
      <SEO title="Products | Weltech Weighing System" canonicalUrl="products" />
      <Layout>
        <h1 className="container" style={{ marginTop: "96px" }}>
          Products
        </h1>
        <div className="main-content">
          {/* Start Blog Area  */}
          {blogList.loading ? (
            <div className="center-flex vh-100">
              <ReactLoading
                type="spinningBubbles"
                color="#1B7284"
                height={"20%"}
                width={"20%"}
              />
            </div>
          ) : (
            <div
              className="rn-blog-area rn-section-gap rn-blog-area1"
              style={{ paddingTop: "36px" }}
            >
              <div className="container">
                <div className="row mt_dec--30">
                  {blogList.data.length ? (
                    <BlogPropTwo
                      blogList={blogList}
                      column={
                        blogList.length <= 2
                          ? "col-lg-6 mt--30"
                          : "col-lg-4 mt--30"
                      }
                      StyleVarProp="card"
                    />
                  ) : (
                    <h1 className="text-center mt--80">Coming Soon</h1>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* End Blog Area  */}
        </div>
      </Layout>
    </>
  );
};

export default BlogListView;
