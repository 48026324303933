import PropTypes from "prop-types";
import React from "react";

const SEO = ({
  title,
  description = "Electronic weighbridge manufacturers in India, electronic weighbridge suppliers in Ahmedabad, weighbridge exporter in Gujarat, pit type pit less, mobile, movable and mechanical weighbridge manufacturer in Ahmedabad, Gujarat, India.",
  keywords = "Weighbridge manufacturers in India, electronic weighbridge exporter in Gujarat, electronic weighbridge suppliers in Ahmedabad, pit type pit less, mobile, movable and mechanical weighbridge manufacturer in Ahmedabad, Gujarat, India.",
  canonicalUrl,
  image,
}) => {
  return (
    <head>
      <meta charSet="utf-8" />

      {title && (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
        </>
      )}
      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />{" "}
        </>
      )}
      {keywords && <meta name="keywords" content={keywords} />}
      <link
        rel="canonical"
        href={`https://weltechweighbridge.com/${canonicalUrl || ""}`}
      />
      <meta
        property="url"
        content={`https://weltechweighbridge.com/${canonicalUrl || ""}`}
      />
      <meta
        property="og:url"
        content={`https://weltechweighbridge.com/${canonicalUrl || ""}`}
      />
      <meta name="robots" content="all" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="Weltech Weighing System" />
      {image && (
        <>
          <meta property="og:image" content={image} />
          <meta name="twitter:image" content={image} />{" "}
        </>
      )}
      <meta property="og:type" content="website" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </head>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
