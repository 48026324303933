import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading"
import ProductDetailsContent from "../components/blog/ProductDetailsContent";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import axios from "axios";

const ProductDetails = ({
  match: {
    params: { productType,  id },
  },
}) => {
  const [blogInfo, setBlogInfo] = useState({});
  const [blogInfoLoading, setBlogInfoLoading] = useState(false);
  const getBlogData = async () => {
    setBlogInfoLoading(true)
    try {
      const response = await axios.get(`https://lzh3bipm.api.sanity.io/v2022-03-07/data/query/production?query=*%5B_type+%3D%3D+%22products%22+%26%26+slug.current+%3D%3D+%22${id}%22+%5D%7B%0A++++++++++++...%2C%0A++++++++++++%7D%7Corder%28_createdAt+desc%29`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SANITY_TOKEN}`,
        },
        params: {},
      });
      setBlogInfo(response.data.result[0])
    } catch (err) {
      console.log("err", err);
    }finally{
      setBlogInfoLoading(false)
    }
  };

  useEffect(() => {
    getBlogData();
  }, []);

  return (
    <>
      <SEO title={`${blogInfo.title} | Weltech Weighing System`} canonicalUrl={`${productType}/${id}`} />
      <Layout>
        <div className="rn-blog-details-area">
          {blogInfoLoading ? <div className="center-flex"> <ReactLoading type="spinningBubbles" color="#1B7284" height={'20%'} width={'20%'} /> </div> : <>{Object.keys(blogInfo).length && (
            <ProductDetailsContent data={blogInfo} />
          )}</>}
          
        </div>
      </Layout>
    </>
  );
};
export default ProductDetails;
