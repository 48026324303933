import React, { useState } from "react";
import { Link } from "react-router-dom";

const Nav = ({ setPortFolioData = () => {}, onClose = () => {} }) => {
  const [categoryList, setCategoryList] = useState([]);

  return (
    <ul className="mainmenu">
      <li className="has-droupdown">
        <Link onClick={() => onClose()} to="/">
          Home
        </Link>
      </li>

      <li>
        <Link to="/profile">Profile</Link>
      </li>

      <li className="has-droupdown with-megamenu">
        <Link to="/products">Product</Link>
        <div className="rn-megamenu">
          <div className="wrapper">
            <div className="row row--0">
              <div className="col-lg-4 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link to="/products/electronic-weighbridge">
                      Electronic weighbridge
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/mechanical-weighbridge">
                      Mechanical Weighbridge
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/mechanical-scale">
                      Mechanical Scale
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/industrial-scale">
                      Industrial Scale
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link to="/products/electronic-scale">
                      Electronic Scale
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/crane-scale">Crane scale</Link>
                  </li>
                  <li>
                    <Link to="/products/weighbridge-accessories">
                      Weighbridge Accessories
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/electronic-systems">
                      {" "}
                      Electronic Systems
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link to="/products/load-cell">Load Cell</Link>
                  </li>
                  <li>
                    <Link to="/products/table-top-scale">Table Top Scale</Link>
                  </li>
                  <li>
                    <Link to="/products/ci-weights">C.I. Weights</Link>
                  </li>
                  <li>
                    <Link to="/products/digital-indicators">
                      Digital Indicators
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className="has-droupdown">
        <Link to="/quality">Quality</Link>
      </li>

      <li className="with-megamenu">
        <Link to="/service">Services</Link>
      </li>

      <li className="has-droupdown">
        <Link to="/gallery">Gallery</Link>
      </li>
      <li>
        <Link to="/payment">Payment</Link>
      </li>

      <li className="has-droupdown">
        <Link to="/contact">Contact</Link>
      </li>
    </ul>
  );
};
export default Nav;
