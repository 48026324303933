import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import Separator from "../separator/Separator";
import axisLogo from "../../assets/images/axis_logo.png";

const Payment = () => {
  return (
    <>
      <SEO title="Payment | Weltech Weighing System" canonicalUrl="payment" />
      <Layout>
        <div className="main-content">
          <div className="rn-service-area rn-section-gap">
            <div className="container">
              <div className="row pt--10">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    title="Payment"
                  />
                  <div className="service-area pt-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="content">
                            <p className="mb--10">
                              We accept cheque/draft in favour of{" "}
                              <strong> "WEL TECH WEIGHING SYSTEM”</strong>{" "}
                              payable at Ahmedabad.
                            </p>
                            <p className="mb--10">
                              <strong>Note:</strong> Please email your payment
                              details to{" "}
                              <strong>weltechweighings@gmail.com</strong> after
                              making a payment
                            </p>{" "}
                            <p className="mb--10">
                              You can directly deposit Cash/Cheque in any AXIS
                              Bank branch or Drop Cheque in any AXIS ATM
                              anywhere in India.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="content">
                            <p className="mb--5 mt--15">
                              <strong> Account Name </strong>:: WEL-TECH
                              WEIGHING SYSTEM
                            </p>
                            <p className="mb--5">
                              <strong>Account Number </strong>:: 922020033843820
                            </p>
                            <p className="mb--5">
                              <strong>Bank</strong> :: AXIS BANK
                            </p>
                            <p className="mb--5">
                              <strong> Branch </strong>:: NEW NARODA AHMEDABAD
                            </p>
                            <p className="mb--5">
                              <strong> RTGS / IFSC NO </strong>:: UTIB0003605
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center col-lg-4">
                          <img src={axisLogo} alt="axis" />
                        </div>
                        <div className="content">
                          <p className="mt--20 font-bold">
                            You can make online payments through Net Banking
                            (EFT/NEFT/RTGS) directly to our AXIS bank account
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Service Area  */}
          <Separator />
        </div>
      </Layout>
    </>
  );
};

export default Payment;
