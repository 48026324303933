import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import TestimonialOne from "./TestimonialOne";

const Testimonial = () => {
  return (
    <>
      <SEO title="Testimonial || PRIY Graphics | Printing | Advertising Services in Surat - React Business  Template" />

      <Layout>
        <div className="main-content">
          {/* Start Elements Area  */}
          <div className="rwt-testimonial-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--10">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Client Feedback"
                    title="Testimonial."
                  />
                </div>
              </div>
              <TestimonialOne
                column="col-lg-3 col-md-6 col-12 mt--30"
                teamStyle="card-style-default testimonial-style-one"
                marginTop={false}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Testimonial;
