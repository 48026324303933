import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import VideoItem from "../elements/video/VideoItem";
import ReactLoading from "react-loading";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import BlogList from "../components/blog/itemProp/BlogList";
import { client } from "../utils/client";
import Carousel from "react-bootstrap/Carousel";
const PopupData = [
  {
    id: "01",
    image: "./images/bg/bg-image-4.jpg",
    popupLink: [
      <iframe
        width="1920px"
        height="1080px"
        src="https://www.youtube.com/embed/crsgqWoPQkA?autoplay=1"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />,
    ],
  },
];

const HomeDefault = () => {
  const [portfolioData, setPortFolioData] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [galleryDataLoading, setGalleryDataLoading] = useState(false);
  const getBlogData = () => {
    setBlogListLoading(true);
    client
      .fetch(
        `*[_type == "products"]{
       ...,
       } | order(_createdAt asc)`,
      )
      .then((response) => {
        setBlogList(response);
        setBlogListLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setBlogListLoading(false);
      });
  };
  const [blogList, setBlogList] = useState([]);

  const [blogListLoading, setBlogListLoading] = useState(false);

  useEffect(() => {
    getBlogData();
  }, []);

  return (
    <>
      <SEO title="Home" />

      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
          setPortFolioData={setPortFolioData}
        />
        {/* Start Slider Area  */}
        <div className="container carousel-container">
          <Carousel fade indicators={false} className="row">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpeg`}
                alt="First slide"
                style={{ width: "800px", height: "550px" }}
              />{" "}
              {/* <Carousel.Caption>
                <h1>First slide label</h1>
                <p>
                  Nulla vitae elit libero, a pharetra augue mollis interdum.
                </p>
              </Carousel.Caption> */}
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/images/bg/bg-image-2.jpeg`}
                alt="First slide"
                style={{ width: "800px", height: "550px" }}
              />{" "}
              {/* <Carousel.Caption>
                <h1>Second slide label</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </Carousel.Caption> */}
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/images/bg/bg-image-3.jpeg`}
                alt="First slide"
                style={{ width: "800px", height: "550px" }}
              />{" "}
              {/* <Carousel.Caption>
                <h1>Third slide label</h1>
                <p>
                  Praesent commodo cursus magna, vel scelerisque nisl
                  consectetur.
                </p>
              </Carousel.Caption> */}
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/images/bg/bg-image-4.jpeg`}
                alt="First slide"
                style={{ width: "800px", height: "550px" }}
              />{" "}
              {/* <Carousel.Caption>
                <h1>Third slide label</h1>
                <p>
                  Praesent commodo cursus magna, vel scelerisque nisl
                  consectetur.
                </p>
              </Carousel.Caption> */}
            </Carousel.Item>
          </Carousel>
        </div>

        <Separator />
        <div className="rwt-portfolio-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title={"Welcome to Weltech Weighing System"}
                  description="Explore our portfolio of innovative and effective design solutions that have helped our clients succeed."
                />
                <div className="service-area pt--50">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="content">
                          <p className="mb--10">
                            Wel-tech Weighing Systems are one of the leading
                            manufacturers, exporters and suppliers of a wide
                            range of Electronic, Mechanical, and Electro –
                            Mechanical Weigh Bridges & Weighing Scales. Our
                            range of product includes Weighing Pad Systems,
                            Mechanical Weighbridge, Table Top Scale, Platform
                            Scale, Bag Filling, Pit Type, Pit Less Weighbridges,
                            Axel and Batching System. In addition to these, we
                            also offer Junction Box, Load Cells, Jumbo Display
                            and Lightning Protector.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <p className="mb--10">
                          We also provide repair services for these products.
                          Backed with around 36 years of experience, our range
                          of products is highly recognized and appreciated for
                          their high performance, consistent quality and
                          additional features.
                        </p>
                        <p className="mb--10">
                          Our product range includes Electronic and Mechanical
                          Weighing Scale like Electronic Weighbridge, Fully
                          Electronic Weighbridge, Axle Weighbridge, Movable
                          Weighbridge, Mechanical Weighbridge, Electronic
                          Weighing Scale, Weighing Automation and Weighbridge
                          Accessories.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Separator />
        {/* Start Video Area  */}
        <div className="rwt-video-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              {PopupData?.map((item) => (
                <div className="col-lg-12" key={item.id}>
                  <VideoItem galleryItem={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Video Area  */}
        <Separator />
        <div className="blog-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Hot Products"
                />
              </div>
            </div>
            <div className="row row--15">
              {blogListLoading ? (
                <div className="center-flex">
                  <ReactLoading
                    type="spinningBubbles"
                    color="#1B7284"
                    height={"20%"}
                    width={"20%"}
                  />
                </div>
              ) : (
                <>
                  {blogList.slice(0, 3)?.map((item) => (
                    <div
                      key={item._id}
                      className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30 blogWrapper"
                    >
                      <BlogList StyleVar="box-card-style-default" data={item} />
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
        {/* Start Elements Area  */}
        <div className="rwt-callto-action-area rn-section-gapBottom">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>
        {/* End Elements Area  */}
        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};
export default HomeDefault;
